import React, { useEffect, useState, lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { ClimbingBoxLoader } from "react-spinners";
import { useSelector, useDispatch } from "react-redux";
import {
  setPageTitleText,
  setFeatureFlag,
  setThemeColor,
  setFeatureFlagPL
} from "./reducers/ThemeOptions";
import qs from "qs";

// Layout Blueprints
import {
  CollapsedSidebar,
  LeftSidebar,
  LeftSidebarFullscreen,
  MinimalLayout,
} from "./layout-blueprints";

// Example Pages
import Login from "./app/auth/LoginForm";

import PageError404 from "./app/pages/PageError404";
import PageError500 from "./app/pages/PageError500";
import PageError505 from "./app/pages/PageError505";
import PageMaintenance from "./app/pages/PageMaintenance";

//Vendor System Pages
const Dashboard = lazy(() => import("./app/VendorSystem/Dashboard/Pages"));
const MyAccount = lazy(() => import("./app/VendorSystem/MyAccount/Pages"));
const FeaturedProducts = lazy(() =>
  import("./app/VendorSystem/Catalog/FeaturedProducts/Pages")
);
const ImportProducts = lazy(() =>
  import("./app/VendorSystem/Catalog/ImportProducts/Pages")
);

const NewProductsLaunch = lazy(() =>
  import("./app/VendorSystem/Catalog/NewProductsLaunch/Pages/NewProductsLaunch")
);

const ProductInfoUpdate = lazy(() =>
  import("./app/VendorSystem/Catalog/ProductInfoUpdate/Pages/ProductInfoUpdate")
);

const PriceReview = lazy(() =>
  import("./app/VendorSystem/Catalog/PriceReview/Pages/PriceReview")
);

const PromotionService = lazy(() =>
  import("./app/VendorSystem/Catalog/PromotionService/Pages/PromotionService")
);

const EventsForSubmissionDetails = lazy(() =>
  import(
    "./app/VendorSystem/Catalog/PromotionService/Pages/EventsForSubmissionDetails"
  )
);

const EventsHistoryDetails = lazy(() =>
  import(
    "./app/VendorSystem/Catalog/PromotionService/Pages/EventsHistoryDetails"
  )
);

const ManageProducts = lazy(() =>
  import("./app/VendorSystem/Catalog/ManageProducts/Pages")
);

const AddProducts = lazy(() =>
  import("./app/VendorSystem/Catalog/ManageProducts/Pages/AddProducts")
);

const Products = lazy(() =>
  import("./app/VendorSystem/Reports/Products/Pages")
);
const Sales = lazy(() => import("./app/VendorSystem/Reports/Sales/Pages"));
const VendorInvoice = lazy(() =>
  import("./app/VendorSystem/Reports/VendorInvoice/Pages")
);

const SalesInvoices = lazy(() =>
  import("./app/VendorSystem/Sales/Invoices/Pages")
);

const SalesInvoiceDetails = lazy(() =>
  import("./app/VendorSystem/Sales/Invoices/Pages/SalesInvoiceDetails")
);
const SalesOrders = lazy(() => import("./app/VendorSystem/Sales/Orders/Pages"));
const SalesOrderDetail = lazy(() =>
  import("./app/VendorSystem/Sales/Orders/Pages/SalesOrderDetails")
);

const SalesShipments = lazy(() =>
  import("./app/VendorSystem/Sales/Shipments/Pages")
);
const SalesUploadShipments = lazy(() =>
  import("./app/VendorSystem/Sales/UploadShipments/Pages")
);

const PageRecover = lazy(() => import("./app/auth/PageRecover"));

const GreenTheme = lazy(() => import("./app/VendorSystem/common/greenTheme"));
const PurpleTheme = lazy(() => import("./app/VendorSystem/common/purpleTheme"));

//Business Partner

const Routes = () => {
  const { showFeatureItems, currentThemeColor } = useSelector(
    (state) => state.ThemeOptions
  );

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!showFeatureItems) {
      let featureFlag =
        qs.parse(location.search, { ignoreQueryPrefix: true }).ff === "true"
          ? true
          : false;
      //if config enables the feature, ignore the query and blindly allow new feature
      if (process.env.REACT_APP_ENABLE_PRICE_REVIEW_FEATURE === "true") {
        featureFlag = true;
      }
      //console.log(featureFlag , "FeatureFlag", props.location);
      if (featureFlag !== showFeatureItems) 
      dispatch(setFeatureFlag(featureFlag));
    } else {
      return;
    }

    //Start -> check parent listing flag
    //Note: This section only work through query string
   /*  if (qs.parse(location.search, { ignoreQueryPrefix: true }).ff2) {
      let featureFlagPL =
        qs.parse(location.search, { ignoreQueryPrefix: true }).ff2 === "true"
          ? true
          : false;
      // console.log("Route Auto Sync ->", featureFlag3 , showFeatureItems3);
      if (featureFlagPL !== showFeatureItemsPL) {
        dispatch(
          setFeatureFlagPL(featureFlagPL)
        );
      }
    } */
    //Check User list for feature flag - parent listing
   /*  if (
      localStorage.getItem('vednorid') &&
      process.env.REACT_APP_FEATURE_USER_LIST
    ) {
      const userList = process.env.REACT_APP_FEATURE_USER_LIST.split(',');
      console.log("Route userList", userList);

      if (userList.includes(localStorage.getItem('vednorid'))) {
        // console.log('User List =>', localStorage.getItem('user_id'), process.env.REACT_APP_FEATURE_USER_LIST);
        dispatch(
          setFeatureFlagPL(true)
        );
      }
    } */
    //End -> check parent listing flag

    //Set theme
    let currentTheme =
      qs.parse(location.search, { ignoreQueryPrefix: true }).theme === "green"
        ? "green"
        : "purple";
    // console.log("currentTheme", currentTheme);
    if (currentTheme && currentThemeColor !== currentTheme)
      dispatch(setThemeColor(currentTheme));

    //Set icon
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    //console.log("currentThemeColor -- ", currentThemeColor, process.env.REACT_APP_PUBLIC_URL);
    if (currentTheme === "green")
      link.href = process.env.REACT_APP_PUBLIC_URL + "favicon.ico";
    else link.href = process.env.REACT_APP_PUBLIC_URL + "favicon_purple.ico";

    //add GA4 script
    if (process.env.REACT_APP_ENABLE_GOOGLE_ANALYTICS) {
      //const gaScript = `<!-- Google tag (gtag.js) --><script async src="https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_MEASUREMENT_ID}"></script><script>window.dataLayer = window.dataLayer || [];function gtag() {dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${process.env.REACT_APP_GOOGLE_MEASUREMENT_ID}');</script>`;
      const head = document.getElementsByTagName('head')[0];
      const gaScript = document.createElement('script');
      gaScript.setAttribute('async', '');
      gaScript.setAttribute(
        'src',
        'https://www.googletagmanager.com/gtag/js?id=' +
          process.env.REACT_APP_GOOGLE_MEASUREMENT_ID
      );

      const gaInnerScript = document.createElement('script');
      gaInnerScript.innerHTML =
        "window.dataLayer = window.dataLayer || [];function gtag() {dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '" +
        process.env.REACT_APP_GOOGLE_MEASUREMENT_ID +
        "');";

      head.appendChild(gaScript);
      head.appendChild(gaInnerScript);
    }

  }, [dispatch]);

  const { pathname } = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99,
    },
    in: {
      opacity: 1,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.01,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.4,
  };

  const SuspenseLoading = () => {
    return (
      <>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={"#3c44b1"} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            LOADING
            <span className="font-size-lg d-block text-dark">
              Please wait while we are loading!
            </span>
          </div>
        </div>
      </>
    );
  };
  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        {currentThemeColor === "green" ? <GreenTheme /> : <PurpleTheme />}
        <Switch>
          {/* {pathname === '/Login/invoiceRedirect' ? <Redirect exact from="/" to="/SalesInvoices" /> : <Redirect exact from="/" to="/Dashboard" />} */}
          <Redirect exact from="/" to="/Dashboard" />
          <Route
            path={[
              "/Dashboard",
              "/FeaturedProducts",
              "/ImportProducts",
              "/NewProducts",
              "/ProductInfoUpdate",
              "/PriceReview",
              "/PromotionService",
              "/EventsForSubmissionDetails",
              "/EventsHistoryDetails",
              "/ManageProducts",
              "/AddProducts",
              "/Reports/Products",
              "/Reports/Sales",
              "/Reports/VendorInvoice",
              "/SalesInvoices",
              "/SalesInvoiceDetails",
              "/SalesOrders",
              "/SalesOrderDetail",
              "/SalesShipments",
              "/SalesUploadShipments",
              "/MyAccount",
              "/MyCreditHistory",
              "/MyOrder",
              "/Notifications",
            ]}
          >
            <LeftSidebar>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <Route path="/Dashboard" component={Dashboard} />
                  <Route path="/MyAccount" component={MyAccount} />
                  <Route
                    path="/FeaturedProducts"
                    component={FeaturedProducts}
                  />
                  <Route path="/ImportProducts" component={ImportProducts} />
                  <Route path="/NewProducts" component={NewProductsLaunch} />
                  <Route
                    path="/ProductInfoUpdate"
                    component={ProductInfoUpdate}
                  />
                  <Route path="/PriceReview" component={PriceReview} />
                  <Route path="/ManageProducts" component={ManageProducts} />
                  <Route
                    path="/PromotionService"
                    component={PromotionService}
                  />
                  <Route
                    path="/EventsForSubmissionDetails/:id"
                    component={EventsForSubmissionDetails}
                  />
                  <Route
                    path="/EventsHistoryDetails/:id"
                    component={EventsHistoryDetails}
                  />
                  <Route path="/AddProducts/:id" component={AddProducts} />

                  <Route path="/Reports/Products" component={Products} />
                  <Route path="/Reports/Sales" component={Sales} />
                  <Route
                    path="/Reports/VendorInvoice"
                    component={VendorInvoice}
                  />

                  <Route path="/SalesInvoices" component={SalesInvoices} />
                  <Route
                    path="/SalesInvoiceDetails/:id"
                    component={SalesInvoiceDetails}
                  />
                  <Route path="/SalesOrders" component={SalesOrders} />
                  {/* /?(:enableAftersales) */}
                  <Route
                    path="/SalesOrderDetail/:id"
                    component={SalesOrderDetail}
                  />
                  <Route path="/SalesShipments" component={SalesShipments} />
                  <Route
                    path="/SalesUploadShipments"
                    component={SalesUploadShipments}
                  />
                </motion.div>
              </Switch>
            </LeftSidebar>
          </Route>
          <Route
            path={[
              "/Login",
              "/Recover",
              "/PageError404",
              "/PageError500",
              "/PageError505",
              "/Maintenance",
            ]}
          >
            <MinimalLayout>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <Route path="/Login" component={Login} />

                  <Route path="/Recover" component={PageRecover} />

                  <Route path="/PageError404" component={PageError404} />
                  <Route path="/PageError500" component={PageError500} />
                  <Route path="/PageError505" component={PageError505} />
                  <Route path="/Maintenance" component={PageMaintenance} />
                </motion.div>
              </Switch>
            </MinimalLayout>
          </Route>
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
