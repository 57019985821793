import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Container, Button } from 'reactstrap';

//import illustration1 from '../../../assets/images/illustrations/pack1/security.svg';
import illustration1 from "../../../assets/images/logo_new.png";
export default function PageMaintenance() {
  return (
    <>
      <div className="app-wrapper bg-white min-vh-100">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5">
                  <Container>
                    <Row>
                      <Col
                        lg="6"
                        xs={{ span: 2, order: 2 }}
                        md={{ span: 2, order: 1 }}
                        className="d-flex align-items-center"
                      >

                        <div className='maintenance-div'>
                            <div className='h1'>
                                Under Maintenance
                            </div>
                            <div className=''>
                                <div style={{ marginBottom: '40px' }}>Dropshipzone is currently undergoing a scheduled maintenance.
                                    This may take up to one hour. During this time you will be unable to log into your account.</div>
                                {/* <div style={{ marginBottom: '40px' }}>Sofortig is currently undergoing a scheduled maintenance as part of our new Retailer Portal.
                                    This may take up to one hour. During this time you will be unable to log into your account.</div> */}
                                {/* <div style={{ marginBottom: '40px' }}>Need more information? 
                                    <a style={{textDecoration:'underline',color:'#000'}} href={DSZ_URL + '/new-retailer-portal'}> Click here to find out more.</a>
                                </div> */}
                                <div style={{ marginBottom: '40px' }}>For urgent matters, please contact your Account Manager or email us at {''}
                                    <a style={{ textDecoration: 'underline', color: '#000' }} href="mailto:info@dropshipzone.com.au">info@dropshipzone.com.au</a>.
                                </div>
                            </div>
                        </div>

                      </Col>
                      <Col
                        lg="6"
                        xs={{ span: 3, order: 1 }}
                        md={{ span: 6, order: 2 }}
                        className="d-flex d-lg-flex align-items-center"
                      >
                        <div className="w-100 pb-4">
                          <img
                            alt="DROPSHIP ZONE"
                            className="mx-auto mb-5 mt-5 d-block img-fluid"
                            src={illustration1}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
